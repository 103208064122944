<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
      >

        <base-card :loading="loading">
          <v-card-text>
            <v-menu
              ref="menuTwo"
              v-model="menuTwo"
              class="text-overline text-lg-subtitle-1"
              :return-value.sync="dates"
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{on, attrs}">
                <v-combobox
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  class="text-overline text-lg-subtitle-1 pa-0"
                  outlined
                  v-on="on"
                />
              </template>

              <v-container class="text-center">
                <v-card>
                  <v-card-text class="align-center">
                    <v-date-picker
                      v-model="dates"
                      range
                      no-title
                      scrollable
                      locale="kr"
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menuTwo = false"
                      >
                        취소
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuTwo.save(dates)"
                      >
                        확인
                      </v-btn>
                    </v-date-picker>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-menu>
          </v-card-text>
          <v-card-actions class="mt-n5">
            <v-btn block dark color="warning" @click="searchEvent">다시조회</v-btn>
          </v-card-actions>
        </base-card>
      </v-col>
      <!-- 지사 수익 -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33"
      >
        <base-card>
          <v-list
            dense
            class="transparent"
          >
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="red"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                가맹점 매출 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getJoinSalesSum }} 원
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="primary"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                소속 대리점 매출 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getSosokSalesSum }} 원
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="warning"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                가맹비 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getJoinSum }} 원
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="indigo"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                추천지사 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getRecommendSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider/>
          <v-list
            color="green accent-5"
            dark
          >
            <v-list-item>
              <v-list-item-title>
                <v-icon
                  x-large
                  color="yellow"
                  class="mx-2"
                >
                  mdi-circle-small
                </v-icon>
                합계 수익
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{ getBenefitSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </base-card>
      </v-col>

      <!-- 대리점 수익 -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 34"
      >
        <base-card>
          <v-list
            dense
            class="transparent"
          >
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="red"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                가맹점 매출 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getJoinSalesSum }} 원
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="primary"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                모집대리점 매출 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getMojibSalesSum }} 원
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-icon
                  x-small
                  color="warning"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                가맹비 수익
              </v-list-item-subtitle>
              <v-list-item-title class="text-right">
                {{ getJoinSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider/>
          <v-list
            color="indigo accent-4"
            dark
          >
            <v-list-item>
              <v-list-item-title>
                <v-icon
                  x-large
                  color="red"
                  class="mx-2"
                >
                  mdi-circle-small
                </v-icon>
                합계 수익
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{ getBenefitSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </base-card>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
      >
        <base-card>
          <div
            color=""
            class="shadow-none"
          >
            <div
              class="d-flex justify-space-between flex-wrap align-center"
            >
              <v-card-title>
                <v-icon
                  x-small
                  color="primary"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                {{ gubunTitle }}
              </v-card-title>
              <div class="pa-2">
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      color=""
                      v-bind="attrs"
                      class="shadow-none"
                      v-on="on"
                    >
                      {{ gubunTitle }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in menuList"
                      :key="index"
                      @click="agencyChange(item)"
                    >
                      <v-list-item-title>
                        {{
                          item
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
      >
        <base-card>
          <v-card-text>
            <div
              class="text-center"
              v-if="tableContentList.length === 0"
            >
              조회된 정보가 없습니다.
            </div>


            <v-simple-table
              fixed-header
              v-if="tableContentList.length > 0"
            >
              <thead>
              <tr>
                <th
                  v-for="(header , index) in tableHeaderList"
                  :key="index"
                  class="text-center"
                >
                  {{ header }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(contentArr , index) in tableContentList"
                :key="'first'+index"
                class="text-center"
              >
                <td v-for="(content , index) in contentArr" :key="index">
                  {{ content }}
                </td>

              </tr>
              </tbody>
            </v-simple-table>

            <v-pagination
              v-if="tableContentList.length > 0"
              v-model="currentPage"
              class="my-4"
              :length="pageCount"
              @next="pageHandler()"
              @previous = "pageHandler()"
              @input = "pageHandler()"
            />


          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {currency, currentDay} from "@/filter/filter";

export default {
  name: 'SalesRevenue',
  created() {
    this.initData()
    this.setSelectedMenu('수익현황')
    if(this.loggedInUser.dealerKind === 33){
      this.gubunTitle = '소속대리점 매출 수익'
      this.menuList = [
        '소속대리점 매출 수익',
        '가맹비 수익',
        '추천 지사 수익',
      ]
    }
    if(this.loggedInUser.dealerKind === 34){
      this.gubunTitle = '가맹점 매출 수익'
      this.menuList = [
        '가맹점 매출 수익',
        '모집대리점 매출 수익',
        '가맹비 수익',
      ]
    }


  },
  mounted() {
    this.fetchMerchantIncomeSum(this.payLoad)
    this.agencyChange( this.gubunTitle)
  },
  data: () => ({
    loading: true,
    currentPage: 1,
    pageCount: 10,
    pageSize: 10,
    dates: [],
    menuTwo: false,
    gubunTitle: '가맹점 매출 수익',
    payLoad: {
      startDt: '',
      endDt: '',
      startNo: 1,
      endNo: 5,
      title: null,
      content: null,
      type: null,
      dealerKind: 0,
      userId: '',
    },
    menuList: [
      '가맹점 매출 수익',
      '모집대리점 매출 수익',
      '가맹비 수익',
    ],
    agencyMenuHeader1: [
      '순번', '가맹점', '결제금액', '수수료수익',
    ],
    agencyMenuHeader2: [
      '순번', '대리점', '매출금액', '수수료수익',
    ],
    agencyMenuHeader3: [
      '순번', '대리점', '등록일', '수수료수익',
    ],
    agency2MenuHeader1: [
      '순번', '대리점', '매출금액', '수수료수익',
    ],
    agency2MenuHeader2: [
      '순번', '대리점', '등록일', '가맹비수익',
    ],
    agency2MenuHeader3: [
      '순번', '지사', '구분', '매출금액', '수익',
    ],
  }),
  computed: {
    ...mapGetters(
      [
        'getJoinSalesSum',
        'getMojibSalesSum',
        'getSosokSalesSum',
        'getJoinSum',
        'getRecommendSum',
        'getBenefitSum',
        'getJoinSalesList',
        'getJoinSalesCount',
        'getMojibSalesList',
        'getMojibSalesCount',
        'getSosokSalesList',
        'getSosokSalesCount',
        'getRecommendList',
        'getRecommendCount',
        'getJoinAmountList',
        'getJoinAmountCount',
        'loggedInUser',
      ]
    ),

    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    // 대리점인경우 메뉴선택에 따른 헤더값 변경
    tableHeaderList() {
      if (this.gubunTitle === '가맹점 매출 수익') return this.agencyMenuHeader1
      if (this.gubunTitle === '모집대리점 매출 수익') return this.agencyMenuHeader2
      if (this.gubunTitle === '가맹비 수익' && this.loggedInUser.dealerKind===34 ) return this.agencyMenuHeader3

      if (this.gubunTitle === '소속대리점 매출 수익') return this.agency2MenuHeader1
      if (this.gubunTitle === '가맹비 수익' && this.loggedInUser.dealerKind===33 ) return this.agency2MenuHeader2
      if (this.gubunTitle === '추천 지사 수익') return this.agency2MenuHeader3

      return this.agencyMenuHeader1
    },
    // 대리점인 경유 메뉴선택에 따른 내용선택
    tableContentList() {
      let targetArr = []
      let tempArr = []
      if (this.gubunTitle === '소속대리점 매출 수익') {
        targetArr = this.getSosokSalesList.map(item => {
          return [item.no, item.memberName, currency(item.amount) + '원', currency(item.feeAmount)+ '원']
        })
      } else if (this.gubunTitle === '추천 지사 수익') {
        targetArr = this.getRecommendList.map(item => {
          return [item.no, item.memberName, '매출' ,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
        })
      }else if (this.gubunTitle === '가맹점 매출 수익') {
        targetArr = this.getJoinSalesList.map(item => {
          return [item.no, item.memberName,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
        })
      } else if (this.gubunTitle === '모집대리점 매출 수익') {
        targetArr = this.getMojibSalesList.map(item => {
          return [item.no, item.memberName,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
        })

      } else if (this.gubunTitle === '가맹비 수익') {
        if(this.loggedInUser.dealerKind===33) {
          targetArr = this.getJoinAmountList.map(item =>{
            return [item.no, item.type, item.cmpnm,  item.createDt ,  currency(item.amount) + '원']
          });
        }
        if(this.loggedInUser.dealerKind===34) {
          targetArr = this.getJoinAmountList.map(item =>{
            return [item.no, item.cmpnm,  item.createDt ,  currency(item.amount) + '원']
          });
        }

      } else {
        targetArr = []
        this.pageCount = 1;
      }
      targetArr.map(item => {
        const firstArr = []
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(item)) {
          firstArr.push(value)
        }
        tempArr.push(firstArr)
      })
      return tempArr;
    },
  },
  methods: {
    searchEvent(){
      this.initData()
      this.fetchMerchantIncomeSum(this.payLoad)
      this.agencyChange(this.gubunTitle)
    },
    ...mapActions([
      'fetchMerchantIncomeSum',
      'fetchJoinSalesList',
      'fetchJoinAmountList',
      'fetchMojibSalesList',
      'fetchSosokSalesList',
      'fetchRecommendList',
    ]),
    ...mapMutations([
      'setJoinSalesList',
      'setJoinSalesCount',
      'setMojibSalesList',
      'setMojibSalesCount',
      'setSosokSalesList',
      'setSosokSalesCount',
      'setRecommendList',
      'setRecommendCount',
      'setJoinAmountList',
      'setJoinAmountCount',
      'setSelectedMenu',
      'toggleThemeLoadingState'
    ]),
    pageHandler () {
      this.payLoad.startNo = (this.currentPage - 1) * this.pageSize + 1
      this.payLoad.endNo = this.pageSize * this.currentPage
      this.agencyChange( this.gubunTitle )
    },
    // 검색파라미터 초기화
    initData() {
      if (this.dates.length === 0) {
        this.dates = [currentDay(true), currentDay()]
      }
      this.payLoad = {
        startDt: this.dates[0],
        endDt: this.dates[1],
        startNo: 1,
        endNo: 10,
        title: null,
        content: null,
        type: null,
        dealerKind: this.loggedInUser.dealerKind,
        userId: this.loggedInUser.dealerId,
      }
    },
    // 목록 데이터 모두 초기화 제거 -- 잔류 데이터로 혼란있었음
    initListData() {
      this.pageCount = 10
      this.setJoinSalesList([])
      this.setJoinSalesCount(0)
      this.setMojibSalesList([])
      this.setMojibSalesCount(0)
      this.setSosokSalesList([])
      this.setSosokSalesCount(0)
      this.setRecommendList([])
      this.setRecommendCount(0)
      this.setJoinAmountList([])
      this.setJoinAmountCount(0)
    },
    // 대리점
    agencyChange(val) {
      this.gubunTitle = val
      this.initListData()
      if (val === '가맹점 매출 수익') {
        this.fetchJoinSalesList(this.payLoad)
          .then(_ => {
            this.pageCount = Math.ceil(this.getJoinSalesCount / this.pageCount)
          })
      } else if (val === '가맹비 수익') {
        this.fetchJoinAmountList(this.payLoad).then(_ => {
          this.pageCount = Math.ceil(this.getJoinAmountCount / this.pageCount)
        })
      } else if (val === '모집대리점 매출 수익') {
        this.fetchMojibSalesList(this.payLoad)
          .then(_ => {
            this.pageCount = Math.ceil(this.getMojibSalesCount / this.pageCount)
          })
      } else if (val === '소속대리점 매출 수익') {
        this.fetchSosokSalesList(this.payLoad)
          .then( _ => {
            this.pageCount = Math.ceil(this.getSosokSalesCount / this.pageCount)
          })
      }
      else if (val === '추천 지사 수익') {
        this.fetchRecommendList(this.payLoad)
          .then( _ => {
            this.pageCount = Math.ceil(this.getRecommendCount / this.pageCount)
          })

      }
    },

  },
}
</script>

<style>

</style>
